import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import StartProject from '../components/Common/StartProject'
import ProjectStartArea from "../components/Index/ProjectStartArea";

const FAQ = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="FAQ" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="FAQ" 
            />
            <div className="ptb-100">
                <div className="container">
                    <div className="faq-accordion">
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q1. What is AR?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>AR is short for Augmented Reality.</p>
                                    <p>Augmented Reality is hidden digital content behind target images only visible through mobile devices. AR describes the process of overlaying real images with unlimited multimedia information, such as further images, videos, links, interactive buttons, music and other media data. Print and online versions of the target can give more information than just the one on first impression – perception of reality expanse. This is what AR is – implicating a sense of virtual reality to ordinary images</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q2. Where does visionar store content that I upload?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>The content will be stored on the visionar server, which is hosted by the hoster “1und1.de”</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>Q3. Can I store content on my personal server?</AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Content cannot be stored locally on a customer server. To use your content in an application the visionar software has to have access to your data all the time. This is why it is necessary to store your content on the visionar server. For any security issues please contact our support team and send an email to <a href={"mailto:support@visionar.com"}>support@visionar.com</a>.</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q4. Can I overlay one image with a video and a link?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Yes, it is possible to use several overlays for one image target.</p>
                                    <p>But you cannot use the overlays on top of each other. You will have to place them on different positions on your image target. In case you want a device to show multiple overlay on one target you will have to place them on one single image target (picture). When each part of a multiple target is uploaded and overlayed separately, devices will only recognize one target at once.</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q5. What are the most common problems with image targets?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>For image targets the requirements are: 8-bit or 24-bit PNG, JPG format in RGB or grayscale, CMYK is not supported. Be sure that your image target has high contrast.</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q6. Can I manage my content by myself and change them whenever I want?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Yes, you can do that with the visionar studio. Visionar’s content management system was built exactly for that purpose.</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q7. What does visionar cost?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>The visionar pricing plans can be viewed on <a href={"https://www.visionar.com/pricing"}>www.visionar.com/pricing</a>.</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q8. What happens if my license is running out?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Usually the visionar license extends automatically. If you cancel your yearly license you will not be able to manage your content inside the released App in the future. As a result, AR Content cannot be shown in your App anymore.</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q9. How can I cancel my license?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>You can terminate your licence in your visionar account or you can contact our support team: <a href={"mailto:support@visionar.com"}>support@visionar.com</a></p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q10. Who is the owner of the content in my app?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>The content remains property of the creator. Visionar only provides the space for using this content. You can find all details in our <a href={"https://www.visionar.com/terms-of-service"}>terms and conditions</a>.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </div>
            <ProjectStartArea />
            <Footer />
        </Layout>
    );
}

export default FAQ